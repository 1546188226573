// drawer options & labels
export const options = [
  {
      label: 'About Us',
      tag: '#about'
  },
  {
      label: 'Services',
      tag: '#services'
  },
  {
      label: 'Clients',
      tag: '#clients'
  },
  {
      label: 'Our Vision',
      tag: '#vision'
  },
  {
      label: 'Contact Us',
      tag: '#contact'
  }
];