import React from 'react';

import { options } from './options';
import styles from './sidedrawer.module.css';

const sideDrawer = ({ show, click }) => {
    return (
    <nav className={[styles.sideDrawer, show ? styles.open : null ].join(' ')}>
        <ul>
            <img onClick={click} className={styles.exitIcon} alt='exit-icon' src={require('./imgs/exit-icon.png')}/>
            <img className={styles.sideDrawerLogo} alt='drawer-logo' src={require('./imgs/drawer-logo.svg')}/>
            <div className={styles.anchorList}>
                {options.map(({label, tag}) => {
                    return (<li key={tag} onClick={click}><a href={tag}>{label}</a></li>)
                })}
            </div>
        </ul>
    </nav>
    );
};

export default sideDrawer; 