import React from 'react';

import styles from './Services.module.css';

const Services = () => {
  return (
    <div className={styles.serviceContainer}>
      <div className={styles.serviceCopyContainer}>
          <h2 className={styles.serviceTitle}>OUR SERVICES</h2>
          <p>FourTwo.One distinguishes itself by offering world class services. While every project is unique, we’ve honed our craft
          to revolve around the four key points.</p>
      </div>

      <div className={styles.serviceCategoriesContainer}>
          <div className={styles.serviceCategory}>
              <img className={styles.serviceImage} src={require('./imgs/strategy.png')} alt='strategy'/>
              <h3>STRATEGY</h3>
              <p>
                  Strategy sits at the heart of it all. From ideation to production,
                  we work with teams of all sizes in order to bring ideas to market at-scale.
              </p>
          </div>
          <div className={styles.serviceCategory}>
              <img className={styles.serviceImage} src={require('./imgs/design.png')} alt='design' />
              <h3>DESIGN</h3>
              <p>
                  Design helps convey the product to market. 
                  With our world class designers, we tailor our strategy
                  to the proper audience and market.
              </p>
          </div>
          <div className={styles.serviceCategory}>
              <img className={styles.serviceImage} src={require('./imgs/development.png')} alt='development' />
              <h3>DEVELOPMENT</h3>
              <p>
                  As the world continues to digitize, our expert developers stay on the cutting edge
                  to provide our clients with custom, scalable solutions.
              </p>
              </div>
          <div className={styles.serviceCategory}>
              <img className={styles.serviceImage} src={require('./imgs/marketing.png')} alt='marketiing' />
              <h3>MARKETING</h3>
              <p>
                  Data speaks. We use top tier analytic tools and techniques to funnel data
                  that drives growth & development in order to make your data work for you.
              </p>
              </div>
        </div>
    </div>
  )
}

export default Services;
