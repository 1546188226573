import React, { Component } from 'react';
import { navigate } from 'gatsby';

import Helmet from 'react-helmet';

import indexStyles from '../styles/index.css'

import Founders from '../components/Founders/Founders';
import Clients from '../components/Clients/Clients';
import SideDrawer from '../components/SideDrawer/sidedrawer';
import Services from '../components/Services/Services';
import About from '../components/About/About';
import Hero from '../components/Hero/Hero';
import Footer from '../components/Footer/Footer';

import { fieldIsValid } from '../components/Footer/form';

class Index extends Component {
    state = {
        sideDrawerOpen: false,
        name: null,
        telephone: null,
        email: null,
        formError: null
    };

    componentDidMount() {
        navigate('/') // always refresh at home page 
    }

    toggleDrawerState = (state) => {
        this.setState({ sideDrawerOpen: !state });
    }

    updateInput = (label, value) => {
        this.setState({ [label]: value });
    }

    setErrorState = (fieldInvalid) => {
        this.setState({ formError: `Please fill out all form fields${fieldInvalid ? ' correctly' : ''}.` })
    }

    submitForm = () => {
        const { name, telephone, email } = this.state;

        if( // check for all values
           (name === null || name === '') ||
           (telephone === null || telephone === '') || 
           (email === null || email === '')
        ) return this.setErrorState();

        if( // check if all values are valid against defined RegEx's
            !fieldIsValid('name', name) ||
            !fieldIsValid('telephone', telephone) ||
            !fieldIsValid('email', email)
        ) return this.setErrorState(true);

        this.setState({ formError: null });

        // TODO: Submit to proper backend
        
    }

    render(){
        const { sideDrawerOpen, formError } = this.state;
        return (
            <div>
                <Helmet
                    title="FourTwo.One"
                    defer={false}
                />
                <Hero drawerAction={() => this.toggleDrawerState(sideDrawerOpen)} />
                <SideDrawer show={sideDrawerOpen} click={() => this.toggleDrawerState(sideDrawerOpen)}  />
                <div className="content"> 
                    <About/>
                    <div id='services'/>
                    <Services/>
                    <div id='clients'/>
                    <Clients/>
                    <div id='vision'/>
                    <br/>
                    <Founders/>
                </div>
                <Footer formError={formError} submitForm={this.submitForm} updateInput={this.updateInput} />
                <div id="contact"></div>
            </div>
        )
    }
}

export default Index;