export const logos = [
  {
    src: require('./imgs/intel-logo.svg'),
    alt: 'intel'
  },
  {
    src: require('./imgs/schwab-logo.svg'),
    alt: 'charles-schwab'
  },
  {
    src: require('./imgs/lord-logo.svg'),
    alt: 'lord'
  },
  {
    src: require('./imgs/biocidics-logo.svg'),
    alt: 'rf biocidics'
  },
  {
    src: require('./imgs/supreme-logo.svg'),
    alt: 'supreme'
  },
  {
    src: require('./imgs/nike-logo.svg'),
    alt: 'nike'
  },
  {
    src: require('./imgs/airbnb-logo.svg'),
    alt: 'airbnb'
  },
  {
    src: require('./imgs/samsung-logo.svg'),
    alt: 'samsung'
  },
  {
    src: require('./imgs/goodwill-logo.svg'),
    alt: 'goodwill'
  },
  {
    src: require('./imgs/salesforce-logo.svg'),
    alt: 'salesforce'
  },
  {
    src: require('./imgs/intel-ai-logo.png'),
    alt: 'intel-ai'
  },
  {
    src: require('./imgs/nokia-logo.png'),
    alt: 'nokia'
  },
  {
    src: require('./imgs/hundreds-logo.svg'),
    alt: 'the-hundreds'
  }
]