import React from 'react';

import styles from './Founders.module.css';

const Founders = () => {
  return (
    <div className={styles.foundersSectionContainer}>
      <div></div>
          <h2 className={styles.founderTitle}>OUR VISION, OUR COMMITMENT</h2>
    
          <div className={styles.foundersContainer}>
            <div className={styles.foundersCommitment}>
                <p className={styles.foundersCommitmentCopy}>"Our team at FourTwo.One has one guiding philosophy, no matter where we happen to be around the world: we must care deeply about what we do, about the relationships we have with our clients, and about the communities where we do our work. This is personal to us."</p>
                {/* <p className={styles.foundersCommitmentCopy}>Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p> */}
                <p className={styles.foundersName}>Luis D. Duval, PhD MBA</p>
                <p className={styles.foundersTitle}>FOUNDER & CEO</p>
            </div>
            <div className={styles.foundersImageContainer}>
              <img className={styles.foundersImage} src={require('./imgs/founder-1.png')} alt='' />
            </div>
      </div>
    </div>
  )
}

export default Founders;