import React from 'react'

import styles from './footer.module.css'

const Footer = ({ updateInput, submitForm, formError }) => {
    return (
        <footer>
            <img src={require('./imgs/component-background.png')} alt='' className={styles.componentBackground}/>
            <div className={styles.titleContainer}>
                <h2 className={styles.contactTitle}>GET IN TOUCH</h2>
                <div className={styles.contactCopyContainer}>
                    <p className={styles.contactCopy}>Interested in working together? Drop us a line at</p>
                    <p className={styles.contactCopy}><a href="mailto:info@fourtwo.one?Subject=Hello!" target="_top">info@fourtwo.one</a> and let's chat about how we can take your ideas further.</p>
                </div>
            </div>
            {/* <div className={styles.contactContent} id="contactsec">
                <form className={styles.footerForm}>
                    <div>
                        <label>Name</label><br/>
                        <input
                            onBlur={(e) => updateInput('name', e.target.value)}
                            type="text"
                            placeholder="John Doe"
                        />
                    </div>

                    <div>
                        <label>Telephone</label><br/>
                        <input 
                            onBlur={(e) => updateInput('telephone', e.target.value)}
                            type='text'
                            placeholder="916.555.4382"
                        />
                    </div>

                    <div>
                        <label>Email</label><br/>
                        <input
                            onBlur={(e) => updateInput('email', e.target.value)}
                            type="email"
                            placeholder="john.doe@gmail.com"
                        />
                    </div>
                </form>

                { formError ? <p className={styles.formError}>{formError}</p> : null }
    
                <button onClick={() => submitForm()} className={styles.contactSubmit}>SUBMIT</button>
                
            </div> */}
            <div className={styles.mediaContainer}>
                <div className={styles.socialMedia}>
                    <a href="https://twitter.com/4TwoOne"><img src={require('./imgs/twitter.png')} alt='twitter' className={styles.socialMediaIcon} /></a>
                    <a href="https://www.facebook.com/4TwoOne"><img src={require('./imgs/facebook.png')} alt='facebook' className={styles.socialMediaIcon} /></a>
                    <a href="https://www.linkedin.com/company/fourtwoone/"><img src={require('./imgs/linkedin.png')} alt='linkedin' className={styles.socialMediaIcon} /></a>
                </div>
                <p className={styles.copyright}>{`© ${new Date().getFullYear()} FourTwoOne LLC, All Rights Reserved.`}</p>
            </div>
        </footer>
    )
}

export default Footer