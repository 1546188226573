import React from 'react';

import styles from './about.module.css';

const About = () => {
  return(
    <div className={styles.aboutContainer} id="about">
        <div className={styles.aboutCopyContainer}>
          <h2 className={styles.aboutTitle}>WE'RE A PRODUCT DESIGN & ENGINEERING FIRM</h2>
          <p className={styles.aboutText}>FourTwo.One is a design & engineering firm with one goal - building experiences that people love. 
            We’re a global group of entrepreneurs, technologists and policy experts with over 30 years of combined experience.</p>
          <p className={styles.aboutText}>We’ve worked with clients all across the world - from startups to global brands. Much of what we do is under NDA, so 
            if you’d like to learn more about us and how we can help, <a href='#contact'> get in touch.</a></p>
        </div>
        <img className={styles.aboutImage} src={require('./imgs/map.png')} alt='global-map' />
        <img className={styles.aboutMobileImage} src={require('./imgs/mobile-map.png')} alt='global-map-mobile' />
    </div>
  )
}

export default About;