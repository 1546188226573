const stringRegex = new RegExp("[a-zA-Z]+");
const numberRegex = new RegExp("^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-./0-9]*$");
const emailRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$");

export const fieldIsValid = (label, value) => {
  let valid = false;
  switch(label) {
    case('name'):
      valid = stringRegex.test(value)
      break;
    case('telephone'):
      valid = numberRegex.test(value);
      break;
    case('email'):
      valid = emailRegex.test(value);
      break
    default:
      valid = false
      break;
  }
  return valid;
}
