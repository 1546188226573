import React from 'react';

import styles from './Clients.module.css';
import { logos } from './logos';

const Clients = () => {
  return (
    <div className={styles.clientsSectionContainer}>
      <div id="clientsec"></div>
          <div className={styles.clientsContainer}>
              <h2 className={styles.clientsTitle}>WE'VE WORKED WITH</h2>
                <ul>
                  {logos.map(({ src, alt }) => {
                    return (
                      <li key={src} className={styles.client}>
                        <img alt={alt} src={src}/>
                      </li>
                    )
                  })}
                </ul>
          </div> 
    </div>
  );
};

export default Clients;
