import React from 'react';

import styles from './Hero.module.css';

const Hero = ({ drawerAction }) => {
    return (
        <header>
            <img src={require('./imgs/hero-background.png')} alt='hero-background' className={styles.heroBackground} />
            <nav>
                <img onClick={drawerAction} alt='nav-icon' className={styles.navIcon} src={require('./imgs/nav-icon.svg')}/>
                <img className={styles.logo} alt='hero-logo' src={require('./imgs/hero-logo.svg')}/>
                <a href="#contact"><h1 className={styles.touch}>Get in Touch</h1></a>
            </nav>
                <h1 className={styles.title}>LET'S BUILD THE <br/> NEXT BIG THING.</h1>
        </header>
    )
}

export default Hero;

